import React from 'react'

export default function ProduceShop() {
	return (
		<div>
			<h1 className='letter-spacing'>Produce Shop</h1>
			<iframe
				width='1280'
				height='720'
				src='https://www.youtube.com/embed/t-l0-b0YqN4'
				title='YouTube video player'
				frameBorder='0'
				allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
				allowFullScreen></iframe>
		</div>
	)
}
