const About = () => {
    return (
        <div>
		<div className='about'>
			<h2>About Me</h2>
            <br/><br/>
            <div className='container'>
            <p className='text'>I am a Software Engineer with over 4 years of programming experience. Currently I am a senior in Computer Science at Portland State University. In 2022 I graduated from Lane Community College with an AAS in Computer Programming. During the final term of that degree I worked as a Web Development Intern at OCDLA, the Oregon Criminal Defense Lawyers Association.
            <br/><br/><br/>
            <a className='links' href='/pdfs/AlanShirkResume.pdf' target='_blank'>Click here to view my resumé!</a></p>
            </div>
		</div>
	</div>
    )
}

export default About
