import React from 'react'

export default function RentTech() {
  return (
    <div>
        <h1 className="letter-spacing">Rent Tech</h1>
        <iframe width="1280" height="720" src="https://www.youtube.com/embed/qeKBVKBlL7o" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  )
}
