import React from 'react'

export default function MyGameList() {
  return (
    <div>
        <h1 className='letter-spacing'>My Game List</h1>
        <iframe width="1280" height="720" src="https://www.youtube.com/embed/tUUEKhYIz3I" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  )
}
